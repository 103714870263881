.AdminTicketHideScroll::-webkit-scrollbar-track {
    /*inset 0 0 6px rgba(0,0,0,0.3)*/
    -webkit-box-shadow: #fff;
    background-color: #ffffff;
    height: 0px;
}

.AdminTicketHideScroll::-webkit-scrollbar {
    width: 10px;
    height: 0px;
    background-color: #fff;
}

.AdminTicketHideScroll::-webkit-scrollbar-thumb {
  background-color: #686868;
  border: 0px solid #cecece;
}